import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HistoricalDataService {
  url = "/api/historical";

  constructor(
    private http:HttpClient
  ) { }

  async getLocationData(id){
    console.log("getting location data in service")
    const response = await this.http.get(this.url+`?id=${id}`).toPromise();
    return response;
  }
}
