import { Component, OnInit } from '@angular/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { SelectedOptionsService } from '../services/selected-options.service';
import {MatCardModule} from '@angular/material/card'

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.css']
})
export class SettingsListComponent implements OnInit {
  range:number;
  unavail :number;

  constructor(private selectedOptionsService:SelectedOptionsService) { 
  }

  ngOnInit(): void {
  }

  onRangeChange(value){
    this.selectedOptionsService.setRange(value);
  }

  onUnavailChange(value){
    this.selectedOptionsService.setUnavail(value);
  }
}
