import { Component, OnInit } from '@angular/core';
import getDistance from 'geolib/es/getDistance';
import {convertDistance} from 'geolib';
import {Location} from "../models/location.model";
import {LocationAvailabilityService} from "../services/location-availability.service"
import { LocationDataService } from '../services/location-data.service';
import { SelectedOptionsService } from '../services/selected-options.service';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { UpdateLocationService } from '../services/update-location.service';
import {MatCardModule} from '@angular/material/card'


@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.css']
})
export class CityListComponent implements OnInit {
  title : "Nearby Available Locations"
  range = 25;
  locations;
  availList;
  unavailList : Location[] = [];
  userCoords = {};
  cityIsSelected = false;
  urlGetShot = "https://https://gettheshot.coronavirus.ohio.gov/"
  locationsLoaded = false;
  statusMessage = "Searching for nearby locations..."
  unavail = false

  constructor(
    private locationAvailabilityService : LocationAvailabilityService,
    private locationDataService : LocationDataService,
    private selectedOptionsService : SelectedOptionsService,
    private updateLocationService : UpdateLocationService,
  ) { 
    this.selectedOptionsService.getUnavailChanged$().subscribe(unavail=>{
      if(unavail==0){
        this.unavail = false;
      }
      else{
        this.unavail = true;
      }
    });
    this.selectedOptionsService.rangeChanged$.subscribe(range=>{
      this.range = range;
    })
    this.updateLocationService.getCurrentMarkerObservable().subscribe(
      x => { 
        this.locationAvailabilityService.getAvailability().then(
          () => {
            this.userCoords = x;
          }
        );
      }
    );
    this.updateLocationService.getGeoLocationErrorSubject().subscribe(
      message => {
        this.statusMessage = message;
      }
    );
    this.locationAvailabilityService.getAvailListSubject().subscribe(
      list => { 
        this.availList = list;
        this.unavailList = this.locationAvailabilityService.getUnavailList();
        this.sortByClosest(this.availList);
        this.sortByClosest(this.unavailList);
      }
    );
  }

  async ngOnInit() {

    await this.locationAvailabilityService.getLocations();
    this.range = this.selectedOptionsService.getRange();
    await this.locationAvailabilityService.getAvailability();
    this.locationsLoaded = true;
    
    
  }

  sortByClosest(list){
    list.sort((loc1, loc2) => {
      return loc1.distanceAway - loc2.distanceAway;
    })
  }

}
