import { HttpClient, HttpClientJsonpModule } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GoogleMapLoadService} from '../services/google-map-load-service.service'
import { ViewEncapsulation } from '@angular/core';
import { LocalizedString, localizedString } from '@angular/compiler/src/output/output_ast';
import { UpdateLocationService } from '../services/update-location.service';
import {AfterViewInit, AfterContentInit} from '@angular/core'
import {} from 'googlemaps';
import { latitudeKeys } from 'geolib';
import { LocationAvailabilityService } from '../services/location-availability.service';
import { Location } from '../models/location.model';
import { DisplayLocationDetailsService } from '../services/display-location-details.service';
import { SelectedOptionsService } from '../services/selected-options.service';
import { CityCardComponent } from '../city-card/city-card.component';
declare var google: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class GoogleMapComponent implements AfterContentInit {  
  mapElement: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  currentLoc;
  availList : Location[];
  unavailList : Location[];
  nearbyMarkers : google.maps.Marker[] = [];
  unavailMarkers : google.maps.Marker[] = [];

  //todo red markers not refreshing when option is changed/loading properly
  constructor(
    private updateLocationService: UpdateLocationService,
    private locationAvailabilityService : LocationAvailabilityService,
    private displayLocationDetailsService : DisplayLocationDetailsService,
    private selectedOptionsService : SelectedOptionsService
  ) { 
    this.selectedOptionsService.getUnavailChanged$().subscribe( async unavail=>{
      if(unavail == 1){
        this.unavailList = await this.locationAvailabilityService.getUnavailList();
        this.removeUnavailMarkers();
        this.addUnavailMarkers();
      }
      else{
        this.removeUnavailMarkers();
      }
    })
    this.locationAvailabilityService.getAvailListSubject().subscribe(
      list => {
        this.availList = list;
        if(selectedOptionsService.getUnavail() == 1){
          this.removeUnavailMarkers();
          this.addUnavailMarkers();
        }
        this.removeOldMarkers();
        this.addNearbyMarkers();
      }
    )
    this.updateLocationService.getMoveMarkerSubject().subscribe(
      coords =>
      {
        const latLng = new google.maps.LatLng(coords["latitude"], coords["longitude"])
        this.addMarker(latLng);
      }
    );
  }

  ngAfterContentInit() {
    this.updateLocationService.getCurrentMarkerObservable().subscribe(
        loc => { 
          this.currentLoc = loc;
        }
    );
    this.updateLocationService.getPageLoadedSubject().subscribe(
      isGeolocated =>{
        this.setLocation(this.currentLoc.latitude, this.currentLoc.longitude);
        const currentLatLng = new google.maps.LatLng(this.currentLoc.latitude,this.currentLoc.longitude);
        if(isGeolocated){
          this.addMarker(currentLatLng);
        }
        
        this.map.addListener('click', e => {
          this.addMarker(e.latLng);
          this.displayLocationDetailsService.hideDetails();
        });
      });
    this.loadMap();
  }

  async loadMap(){
    this.mapElement = document.getElementById('google-map-element');
    this.updateLocationService.loadCurrentMarkerCoords();
 }

 setLocation(x,y){
   const mapProperties = {
    center: new google.maps.LatLng(x,y),
    zoom: 10,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true
   };
   this.map = new google.maps.Map(this.mapElement, mapProperties);
 }

  addMarker(newLatLng){
    if(this.marker==null){
      this.marker = new google.maps.Marker({
        position: newLatLng,
        map: this.map
      });
    }
    else{
      const coords = {
        latitude: newLatLng.lat(),
        longitude: newLatLng.lng()
      }
      this.marker.setPosition(newLatLng);
      this.updateLocationService.setCurrentMarkerCoords(coords);
    }
    this.map.setZoom(10);
    this.map.panTo(newLatLng);
  }

  addNearbyMarkers(){
    this.availList.forEach(loc => {
      const iconOptions = {
        path: 'M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9 C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8 c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z',
        fillColor: "green",
        fillOpacity: 1,
        scale: 0.1,
        strokeColor: 'green',
        strokeWeight: 1,
        anchor: new google.maps.Point(185, 500)
      }
      const latLng = new google.maps.LatLng(loc.cityCoords.latitude,loc.cityCoords.longitude);
      const currMarker = new google.maps.Marker({
        icon: iconOptions,
        position: latLng,
        map: this.map
      });
      currMarker.addListener('click', e=>{
        //open details panel for marker location
      });
      this.nearbyMarkers.push(currMarker);
    });
    if(this.selectedOptionsService.getUnavail() == 1){
      this.unavailList = this.locationAvailabilityService.getUnavailList();
      this.removeUnavailMarkers();
      this.addUnavailMarkers();
    }
  }

  addUnavailMarkers(){
    this.unavailList.forEach(loc => {
      const iconOptions = {
        path: 'M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9 C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8 c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z',
        fillColor: "red",
        fillOpacity: 1,
        scale: 0.1,
        strokeColor: 'red',
        strokeWeight: 1,
        anchor: new google.maps.Point(185, 500)
      }
      const latLng = new google.maps.LatLng(loc.cityCoords.latitude,loc.cityCoords.longitude);
      const currMarker = new google.maps.Marker({
        icon: iconOptions,
        position: latLng,
        map: this.map
      });
      currMarker.addListener('click', e=>{
        //open details panel for marker location
      });
      this.unavailMarkers.push(currMarker);
    });
  }

  removeOldMarkers(){
    if(this.nearbyMarkers){
      this.nearbyMarkers.forEach(marker=>{
        marker.setMap(null);
      })
      this.nearbyMarkers=[];
    }
  }
  removeUnavailMarkers(){
    if(this.unavailMarkers){
      this.unavailMarkers.forEach(marker=>{
        marker.setMap(null);
      })
      this.unavailMarkers=[];
    }
  }

}