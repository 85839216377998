import { Component, OnInit, Input, ChangeDetectorRef, AfterViewChecked, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { Location } from '../models/location.model';
import { DisplayLocationDetailsService } from '../services/display-location-details.service';
import { LocationAvailabilityService } from '../services/location-availability.service';
import { UpdateLocationService } from '../services/update-location.service';
import {} from 'googlemaps';
import { Resolve } from '@angular/router'
import {MatButtonModule} from '@angular/material/button';
import {MatButton} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatTabsModule} from '@angular/material/tabs';
import { HistoricalDataService } from '../services/historical-data.service';
import CalHeatMap from 'cal-heatmap'
import {MatIconModule} from '@angular/material/icon'

;
import { MobileControlDialogService } from '../mobile-control-dialog.service';
declare var google: any;

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.css']
})
export class LocationDetailsComponent implements OnInit{
  @Input() currentLocation : Location  = new Location();
  dummyLocation : Location = {
    id: 1,
    url:'http://www.walgreens.com',
    city:'Avon Lake',
    name:'Walgreens',
    state:'Ohio',
    address:'32798 Walker Rd',
    distanceAway: 2,
    providerId: 47,
    cityCoords:{
      latitude:41.4937798,
      longitude:-82.0202855
    },
    vaccineType:'Pfizer',
  }
  vaccineLogoUrl = "";
  @Input() providerLogoUrl;
  vaccineUrl = "";
  @Input() isAvail;
  panorama: google.maps.StreetViewPanorama
  @Input() isLoading;
  @Input() historicalData;
  @Input() mobile;
  selectedIndex;
  observer
  cal : CalHeatMap
  placeholderImage = "/assets/images/providers/placeholder.svg";
  @Input() isClinic;

  constructor(
    private updateLocationService : UpdateLocationService,
    private cdr : ChangeDetectorRef,
    private historicalDataService : HistoricalDataService,
    private displayLocationDetailsService: DisplayLocationDetailsService,
    private mobileControlDialogService : MobileControlDialogService
  ) { 
    this.displayLocationDetailsService.getLocationDataChanged().subscribe(arg=>{
      this.selectedIndex = 0;
    });
  }

  

  ngOnInit(): void {
    //if vaccine types are added
    //this.vaccineLogoUrl = "/assets/images/types/"+this.currentLocation.vaccineType+".svg";
    
    if(this.currentLocation.vaccineType == 'Pfizer'){
      this.vaccineUrl = 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/Pfizer-BioNTech.html'
    }
    else if(this.currentLocation.vaccineType == 'Moderna'){
      this.vaccineUrl = 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/Moderna.html'
    }
    else{
      this.vaccineUrl = 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/janssen.html'
    }
  }


  onTabChanged($e){
    console.log("tab changed")
    if(this.selectedIndex==1){
      this.tryRenderHeatMap();
    }
  }

  tryRenderHeatMap(){
    if(document.getElementById("cal-heatmap")){
      this.initHeatMap();
    }
    else{
      setTimeout(()=>{
        this.tryRenderHeatMap()
      }, 1000);
    }
  }
  openUrl(url){
    window.open(url);
  }
  
  async initHeatMap(){
    const data = this.transformData(this.historicalData);
    if(this.cal){
      console.log("repopulate heatmap")
      this.cal.update(data);
    }
    else{
      console.log("initheatmap")
      const range = 14
      this.cal = new CalHeatMap();
      
      const date = new Date();
      date.setDate(date.getDate()-range+1)
      await this.cal.init({
        itemSelector: "#cal-heatmap",
        domain: "day",
        range: range,
        start: date,
        data: data,
        colLimit: 24,
        verticalOrientation: true,
        cellSize: 25,
        displayLegend: false,
        label: {
          position: "left",
          width: 46
        },	
        tooltip: true

      });
    }
    
  }

  transformData(data){
    const newData={};
    data.times.forEach(time =>{
      const timeUnix = this.transformTimeFormat(time)
      newData[timeUnix] = 1;
    });
    return newData;
  }
  
  transformTimeFormat(time){
    //2021-03-25T23:59:38.865+00:00 format
    //Math.round(new Date("2013/09/05 15:34:00").getTime()/1000)
    const yyyy = time.slice(0,4);
    const mm = time.slice(5,7);
    const dd = time.slice(8,10);
    const hh = time.slice(11,13);
    const MM = time.slice(14,16);
    const ss = time.slice(17,19);
    const dateString = `${yyyy}/${mm}/${dd} ${hh}:${MM}:${ss}`;
    
    const unixTime = new Date(dateString).getTime()/1000;
    return unixTime;
  }

  closeLocation(){
    this.mobileControlDialogService.closeDialog();
  }
}
