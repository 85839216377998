import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { CityListComponent } from '../city-list/city-list.component';
import { SelectedOptionsService } from '../services/selected-options.service';
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import { SearchingLocationService } from '../services/searching-location.service';
import { HttpClient } from '@angular/common/http';
import { UpdateLocationService } from '../services/update-location.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { DisplayLocationDetailsService } from '../services/display-location-details.service';
import { ThrowStmt } from '@angular/compiler';
import { LocationAvailabilityService } from '../services/location-availability.service';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatSidenav } from '@angular/material/sidenav'
import {MatTabsModule} from '@angular/material/tabs';
import {SettingsListComponent} from '../settings-list/settings-list.component'
import { HistoricalDataService } from '../services/historical-data.service';
import {MatDividerModule} from '@angular/material/divider'
import { MobileControlDialogService } from '../mobile-control-dialog.service';


@Component({
  selector: 'app-info-modal-overlay',
  templateUrl: './info-modal-overlay.component.html',
  styleUrls: ['./info-modal-overlay.component.css']
})
export class InfoModalOverlayComponent implements OnInit {
  defaultView = true;
  title = "";
  subtitle = "";
  isSearching = false;
  locationString = "Avon Lake, OH"
  range = 25;
  query;
  showDetails = false;
  currentLocation;
  providerLogoUrl;
  availList;
  isAvail;
  isLoading=true;
  drawer : MatSidenav;
  selectedIndex;
  @ViewChild('drawer', { static: false }) set content(content: MatSidenav) {
    console.log(this.showDetails);
    if(this.showDetails) { 
        console.log(content);
        this.drawer = content;
        //this.drawer.open();
    }
 }
 historicalData;
 mobile;
 showingResults = false;
 dialogOpenMobile = false;
 isClinic = false;


  constructor(
    private selectedOptionsService : SelectedOptionsService,
    private searchingLocationService : SearchingLocationService,
    private updateLocationService : UpdateLocationService,
    private displayLocationDetailsService : DisplayLocationDetailsService,
    private locationAvailabilityService : LocationAvailabilityService,
    private historicalDataService : HistoricalDataService,
    private mobileControlDialogService : MobileControlDialogService
  ) { 
    this.mobileControlDialogService.dialogOpenChange$.subscribe(dialogOpen =>{
      this.dialogOpenMobile = dialogOpen;
    })
    this.selectedOptionsService.getRangeChanged$().subscribe(range =>
      {
        this.range = range;
        this.selectedIndex = 0;
      })
      this.selectedOptionsService.getUnavailChanged$().subscribe(unavail =>{
        this.selectedIndex = 0;
      })
    this.displayLocationDetailsService.getToggleDetails().subscribe(
      showDetails => {
        this.showDetails = showDetails;
      }
      
    )
    this.displayLocationDetailsService.getLocationDataChanged().subscribe(async location=>{
      this.currentLocation = location;
      if(this.currentLocation.providerId)
      {
        this.providerLogoUrl = "/assets/images/providers/"+this.currentLocation.providerId+".svg"
        this.isClinic = false;
      }
      else{
        this.isClinic = true;
      }
      if(this.availList.includes(this.currentLocation)){
        this.isAvail = true;
      }
      else{
        this.isAvail = false;
      }
      console.log('setting historical data')
      const getData = await this.setHistoricalData();
      console.log(this.historicalData);
    })
    this.updateLocationService.getGeoDataSubject().subscribe(
      geoData => {
        this.locationString = geoData["plus_code"]["compound_code"].slice(8,-5);
        this.query = "";
      }
    )
    this.locationAvailabilityService.getAvailListSubject().subscribe(
      availList =>{
        this.availList = availList;
      }
    )
  }

  ngOnInit() {
    this.searchingLocationService.getIsSearchingSubject().subscribe(
      bool => {
        this.isSearching = bool;
      }
    )
    if (window.screen.width <= 900) { // 768px portrait
      this.mobile = true;
    }
    else{
      this.mobile = false;
    }
  }

  onLocationCloseButtonClick(){
    this.searchingLocationService.setToSearching();
  }

  async keyDownFunction(event) {
    if (event.keyCode === 13) {
      const result = await this.searchingLocationService.searchForLocation(this.query);
    }
  }

  async setHistoricalData(){
    const response = await this.historicalDataService.getLocationData(this.currentLocation.id);
    console.log(response)
    this.historicalData = response;
  }

  revealResults(){
    this.showingResults = true;
  }

  hideResults(){
    this.showingResults = false;
  }
}
