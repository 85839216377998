import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CityCardComponent } from '../city-card/city-card.component';
import { Location } from '../models/location.model';
import { HistoricalDataService } from './historical-data.service';

@Injectable({
  providedIn: 'root'
})
export class DisplayLocationDetailsService {
  locationData : Location
  locationDataChanged$ : Subject<Location> = new Subject<Location>();
  toggleDetails$ : Subject<boolean> = new Subject<boolean>();
  showingDetails : boolean
  activeTab : CityCardComponent

  constructor(
    private historicalDataService : HistoricalDataService
  ) { 
  }

  getLocationDataChanged(){
    return this.locationDataChanged$;
  }

  getToggleDetails(){
    return this.toggleDetails$;
  }

  showDetails(locationData){
    this.locationData = locationData;
    this.locationDataChanged$.next(this.locationData);
    this.showingDetails = true;
    this.toggleDetails$.next(true);
  }

  hideDetails(){
    this.showingDetails = false;
    this.toggleDetails$.next(false);
  }

}
