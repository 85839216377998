import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedOptionsService {
  range = 25;
  rangeChanged$ : Subject<number> = new Subject<number>();
  unavail = 0;
  unavailChanged$ : Subject<number> = new Subject<number>();
  constructor() { }

  getRange(){
    return this.range;
  }

  getRangeChanged$(){
    return this.rangeChanged$;
  }

  getUnavail(){
    return this.unavail;
  }

  getUnavailChanged$(){
    return this.unavailChanged$;
  }

  setRange(val){
    this.range = val;
    this.rangeChanged$.next(this.range);
  }

  setUnavail(val){
    this.unavail = val;
    this.unavailChanged$.next(this.unavail);
  }
}
