import { Injectable } from '@angular/core';
import {Location} from "../models/location.model";
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { LocationDataService } from './location-data.service';
import getDistance from 'geolib/es/getDistance';
import {convertDistance} from 'geolib';
import { UpdateLocationService } from './update-location.service';
import { SelectedOptionsService } from './selected-options.service';
import { ThrowStmt } from '@angular/compiler';



@Injectable({
  providedIn: 'root'
})
export class LocationAvailabilityService {
  url = "/api/ohio-location-availability";
  availList : Location[] = [];
  availListChanged$ : Subject<Location[]> = new Subject<Location[]>();
  unavailList : Location[] = [];
  unavailListChanged$ : Subject<{}>
  userCoords;
  locations;
  range = 25;
  unavail:boolean = false;
  nearbyClinics = [];
  nearbyClinicsChanged$ : Subject<Location[]> = new Subject<Location[]>();

  constructor(private http : HttpClient,
    private locationDataService : LocationDataService,
    private updateLocationService : UpdateLocationService,
    private selectedOptionsService : SelectedOptionsService) {
      this.selectedOptionsService.rangeChanged$.subscribe(range=>{
        this.range = range;
        this.getAvailability();
      })
      this.selectedOptionsService.getUnavailChanged$().subscribe(unavail=>{
        if(unavail == 0){
          this.unavail = false;
        }
        else{
          this.unavail = true;
        }
        this.getAvailability();
      })
      this.updateLocationService.getCurrentMarkerObservable().subscribe(
        x => { 
          this.userCoords = x;
        }
      );
  }

  async getClinics(){
    const data = [
      {
        "id": 1,
        "name": "Ohio Northern University - Crawford County Mobile Clinic",
        "address": "Crawford County Fairgrounds, 610 Whetstone St.",
        "latitude": 40.8018306,
        "longitude": -82.9587888,
        "city": "Bucyrus",
        "state": "OH",
        "zip": 44820,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 2,
        "name": "Ohio Northern University - Hardin County Mobile Clinic",
        "address": "Ohio Northern University, 525 S. Main St.",
        "latitude": 40.766267,
        "longitude": -83.8346943,
        "city": "Ada",
        "state": "OH",
        "zip": 45810,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 3,
        "name": "Ohio Northern University - Marion County Mobile Clinic",
        "address": "Marion County Fairgrounds, 220 E. Fairground St.",
        "latitude": 40.6041605,
        "longitude": -83.1266775,
        "city": "Marion",
        "state": "OH",
        "zip": 43302,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 4,
        "name": "Ohio Northern University - Union County Mobile Clinic",
        "address": "Union County Fairgrounds, 845 N. Main St.",
        "latitude": 40.2463242,
        "longitude": -83.3698435,
        "city": "Marysville",
        "state": "OH",
        "zip": 43040,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 5,
        "name": "Ohio Northern University - Wyandot County Mobile Clinic",
        "address": "Carey Public School, 2016 Blue Devil Drive",
        "latitude": 40.9452823,
        "longitude": -83.3894475,
        "city": "Carey",
        "state": "OH",
        "zip": 43316,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 6,
        "name": "Ohio Northern University - Wyandot County Mobile Clinic",
        "address": "Sycamore Event Center, 3498 State Highway 103",
        "latitude": 40.9502449,
        "longitude": -83.1811297,
        "city": "Sycamore",
        "state": "OH",
        "zip": 44882,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 7,
        "name": "Ohio University - Lawrence County",
        "address": "Mamre Baptist Church, 2367 County Road 182",
        "latitude": 38.5601947,
        "longitude": -82.5823034,
        "city": "Kitts Hill",
        "state": "OH",
        "zip": 45645,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 8,
        "name": "Ohio University - Meigs County",
        "address": "Racine Methodist Church, 818 Elm St.",
        "latitude": 38.9709492,
        "longitude": -81.9121313,
        "city": "Racine",
        "state": "OH",
        "zip": 45771,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 9,
        "name": "Ohio University - Morgan County",
        "address": "Heck Harkins Community Center, 1685 Broadway St.",
        "latitude": 39.5484357,
        "longitude": -81.7983927,
        "city": "Stockport",
        "state": "OH",
        "zip": 43787,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 10,
        "name": "Ohio University - Morgan County",
        "address": "Morgan West Elementary School, 9675 West State Route 37",
        "latitude": 39.6647396,
        "longitude": -82.0089389,
        "city": "Malta",
        "state": "OH",
        "zip": 43758,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 11,
        "name": "Ohio University - Perry County",
        "address": "Southern Local Schools, 10397 OH-155",
        "latitude": 39.591234,
        "longitude": -82.1442399,
        "city": "Corning",
        "state": "OH",
        "zip": 43730,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 12,
        "name": "Ohio University - Vinton County",
        "address": "Forget Me Knot event center, 64 W. Chestnut St.",
        "latitude": 39.2849509,
        "longitude": -82.4018013,
        "city": "Zaleski",
        "state": "OH",
        "zip": 45698,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 13,
        "name": "Ohio University - Washington County",
        "address": "Cutler Community Center, 4550 Two Mile Run Road",
        "latitude": 39.3489051,
        "longitude": -81.7980926,
        "city": "Cutler",
        "state": "OH",
        "zip": 45724,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 14,
        "name": "Chillicothe Long-Term Clinic",
        "address": "Adena Health System’s PACCAR Medical Education Center, 446 Hospital Road",
        "latitude": 39.3988331,
        "longitude": -82.9735887,
        "city": "Chillicothe",
        "state": "OH",
        "zip": 45601,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 15,
        "name": "Cincinnati Long-Term Clinic",
        "address": "Cintas Center at Xavier University, 1624 Herald Ave.",
        "latitude": 39.1503763,
        "longitude": -84.4740526,
        "city": "Cincinnati",
        "state": "OH",
        "zip": 45207,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 16,
        "name": "Cleveland Eight-Week Clinic",
        "address": "Wolstein Center at Cleveland State University, 2000 Prospect Ave. E.",
        "latitude": 41.4996773,
        "longitude": -81.6785592,
        "city": "Cleveland",
        "state": "OH",
        "zip": 44115,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 17,
        "name": "Columbus Long-Term Clinic",
        "address": "Celeste Center at the Ohio Expo Center & State Fair, 717 E. 17th Ave.",
        "latitude": 39.9977082,
        "longitude": -82.9930822,
        "city": "Columbus",
        "state": "OH",
        "zip": 43211,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 18,
        "name": "Columbus Pop-Up Clinic",
        "address": "St. John Arena at Ohio State University, 410 Woody Hayes Drive",
        "latitude": 40.0054022,
        "longitude": -83.0213739,
        "city": "Columbus",
        "state": "OH",
        "zip": 43210,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 19,
        "name": "Dayton Long-Term Clinic",
        "address": "Dayton Convention Center, 22 E. Fifth St.",
        "latitude": 39.7567742,
        "longitude": -84.1924404,
        "city": "Dayton",
        "state": "OH",
        "zip": 45402,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 20,
        "name": "Dayton Long-Term Clinic - Minority Outreach",
        "address": "Bethesda Temple, 3701 Salem Ave.",
        "latitude": 39.7966807,
        "longitude": -84.2504339,
        "city": "Dayton",
        "state": "OH",
        "zip": 45406,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 21,
        "name": "Lima Long-Term Clinic",
        "address": "Knights of Columbus, 810 S. Cable Road",
        "latitude": 40.7295859,
        "longitude": -84.151061,
        "city": "Lima",
        "state": "OH",
        "zip": 45805,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 22,
        "name": "Mansfield/Richland County Mobile Clinic",
        "address": "Richland County Fairgrounds, 750 N. Home Road",
        "latitude": 40.7801059,
        "longitude": -82.5694521,
        "city": "Mansfield",
        "state": "OH",
        "zip": 44906,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 23,
        "name": "Mansfield/Richland County Mobile Clinic",
        "address": "St. Paul Lutheran Church, 48 Church St.",
        "latitude": 40.6201537,
        "longitude": -82.5157494,
        "city": "Bellville",
        "state": "OH",
        "zip": 44813,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 24,
        "name": "Mansfield/Richland County Mobile Clinic",
        "address": "Franklin Township Fire Station, 505 Boyce Road",
        "latitude": 40.8652428,
        "longitude": -82.5340442,
        "city": "Shelby",
        "state": "OH",
        "zip": 44875,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 25,
        "name": "Marietta Long-Term Clinic",
        "address": "Wayne Street Medical Campus, 330 E. Eighth St.",
        "latitude": 39.4107265,
        "longitude": -81.4403589,
        "city": "Marietta",
        "state": "OH",
        "zip": 45750,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 26,
        "name": "Maumee Long-Term Clinic",
        "address": "Lucas County Recreation Center, 2901 Key St.",
        "latitude": 41.5844324,
        "longitude": -83.6465388,
        "city": "Maumee",
        "state": "OH",
        "zip": 43537,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 27,
        "name": "Seneca County Long-Term Clinic",
        "address": "1600 N. Union St.",
        "latitude": 41.1778403,
        "longitude": -83.4206389,
        "city": "Fostoria",
        "state": "OH",
        "zip": 44830,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 28,
        "name": "Steubenville/Jefferson County and East-Central Ohio Mobile Clinic",
        "address": "Downtown Steubenville, 260 N. 7th St.",
        "latitude": 40.3638876,
        "longitude": -80.6196147,
        "city": "Steubenville",
        "state": "OH",
        "zip": 43952,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 29,
        "name": "Steubenville/Jefferson County and East-Central Ohio Mobile Clinic",
        "address": "Karaffa Recreation Center, 1307 Dennis Way",
        "latitude": 40.4533392,
        "longitude": -80.6149489,
        "city": "Toronto",
        "state": "OH",
        "zip": 43964,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 30,
        "name": "Steubenville/Jefferson County and East-Central Ohio Mobile Clinic",
        "address": "Buckeye Local High School, 10692 OH-150",
        "latitude": 40.1934326,
        "longitude": -80.7214334,
        "city": "Rayland",
        "state": "OH",
        "zip": 43943,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 31,
        "name": "Steubenville/Jefferson County and East-Central Ohio Mobile Clinic",
        "address": "Edison High School, 9890 OH-152",
        "latitude": 40.4484697,
        "longitude": -80.7488244,
        "city": "Richmond",
        "state": "OH",
        "zip": 43944,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 32,
        "name": "Tallmadge Long-Term Clinic",
        "address": "Summit County Fairgrounds, 1050 North Ave.",
        "latitude": 41.1209661,
        "longitude": -81.4350969,
        "city": "Tallmadge",
        "state": "OH",
        "zip": 44278,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 33,
        "name": "Wilmington Long-Term Clinic",
        "address": "Wilmington Air Park Welcome Center, 1199 Airborne Road",
        "latitude": 39.4173509,
        "longitude": -83.8011082,
        "city": "Wilmington",
        "state": "OH",
        "zip": 45177,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 34,
        "name": "Youngstown Long-Term Clinic",
        "address": "Southern Park Mall, 7401 Market St.",
        "latitude": 41.0208703,
        "longitude": -80.661484,
        "city": "Boardman",
        "state": "OH",
        "zip": 44512,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      },
      {
        "id": 35,
        "name": "Zanesville Long-Term Clinic",
        "address": "Colony Square Mall 3575 Maple Ave.",
        "latitude": 39.987624,
        "longitude": -82.029275,
        "city": "Zanesville",
        "state": "OH",
        "zip": 43701,
        "url": "https://gettheshot.coronavirus.ohio.gov",
        "phone": "1-833-4-ASK-ODH"
      }
    ];
    this.nearbyClinics = [];
    data.forEach(location=>{
      location["geometry"] = {
        coordinates : [location.longitude,location.latitude]
      };
      const locObj = this.instantiateLocation(location);
      if(locObj.distanceAway<this.range){
        this.nearbyClinics.push(locObj);
      }
    })
    this.nearbyClinicsChanged$.next(this.nearbyClinics);
  }

  async getAvailability(){
    const data = await this.http.get(this.url).toPromise();
    const availIdList = data["availability"];
    this.availList = [];
    this.unavailList = [];
    if(this.locations){
      
      this.locations.forEach(location => {
        const locObj = this.instantiateLocation(location);
        if(locObj.distanceAway<this.range){
          if(availIdList.includes(location.id)){
            this.availList.push(locObj);
          }
          else{
            this.unavailList.push(locObj);
          }
        }
      });
    } 
    //transmit that availList changed
    this.availListChanged$.next(this.availList);
  }

  instantiateLocation(location){
    const locationObject = new Location();

    locationObject.id = location.id;
    locationObject.providerId = location.provider_id;

    const cityWords = location.city.split(" ")
      locationObject.city = "";
      cityWords.forEach(word => {
      locationObject.city += word.charAt(0).toUpperCase() + word.toLowerCase().slice(1) + " ";
    });
    locationObject.city = locationObject.city.slice(0,-1);

    locationObject.name = location.name;
    locationObject.url = location.url;
    locationObject.state = location.state;
    locationObject.address = location.address;
    locationObject.cityCoords = {
      latitude: location.geometry.coordinates[1], 
      longitude: location.geometry.coordinates[0]
    };

    //additional transformation based on location
    //walgreens
    if(location.provider_id == 47){
      const addressWords = locationObject.address.split(" ")
      locationObject.address = "";
      cityWords.forEach(word => {
        locationObject.address += word.charAt(0).toUpperCase() + word.toLowerCase().slice(1) + " ";
      });
      locationObject.address = locationObject.address.slice(0,-1);
      locationObject.name = "Walgreens"
      locationObject.vaccineType = "Pfizer";
    }
    //walmart
    else if(location.provider_id==70){
      locationObject.name = "Walmart Supercenter";
    }
    else if(location.provider_id==19){
      locationObject.name = "CVS Pharmacy";
    }

    if(!location.geometry.coordinates[0]){
      return locationObject;
    }
    locationObject.distanceAway = this.calculateDistance(this.userCoords, locationObject.cityCoords);
    return locationObject;
  }

  calculateDistance(coords1, coords2){
    //check if coords2 is geolocation data
    if(!coords1 || !coords2){
      return;
    }
    const distanceInMeters = getDistance(
      {
        latitude: coords1.latitude, 
        longitude: coords1.longitude
      }, 
      {
        latitude: coords2.latitude,
        longitude: coords2.longitude
      });
    return Math.round(convertDistance(distanceInMeters, 'mi'));
  }

  getAvailList(){
    return this.availList;
  }

  getUnavailList(){
    return this.unavailList;
  }

  getAvailListSubject(){
    return this.availListChanged$;
  }

  updateAvailList(){
    this.availListChanged$.next(this.availList);
  }

  async getLocations(){
    console.log("getting locations");
    this.locations = await this.locationDataService.getLocations();
  }


}
