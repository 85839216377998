import { Component, OnInit } from '@angular/core';
import { LocationAvailabilityService } from '../services/location-availability.service';
import { LocationDataService } from '../services/location-data.service';
import { SelectedOptionsService } from '../services/selected-options.service';
import { UpdateLocationService } from '../services/update-location.service';

@Component({
  selector: 'app-clinic-list',
  templateUrl: './clinic-list.component.html',
  styleUrls: ['./clinic-list.component.css']
})
export class ClinicListComponent implements OnInit {
  
  nearbyClinics = []
  cityIsSelected : boolean = false;
  urlGetShot = "https://https://gettheshot.coronavirus.ohio.gov/"
  range = 25;
  locations;
  userCoords = {};
  unavail = false;
  locationsLoaded;

  constructor(
    private locationAvailabilityService : LocationAvailabilityService,
    private locationDataService : LocationDataService,
    private selectedOptionsService : SelectedOptionsService,
    private updateLocationService : UpdateLocationService,
  ) { 
    this.selectedOptionsService.rangeChanged$.subscribe(range=>{
      this.range = range;
    })
    this.updateLocationService.getCurrentMarkerObservable().subscribe(
      x => { 
        this.locationAvailabilityService.getClinics().then(
          () => {
            this.userCoords = x;
          }
        );
      }
    );
    this.locationAvailabilityService.nearbyClinicsChanged$.subscribe(
      list => { 
        this.nearbyClinics = list;
        this.sortByClosest(this.nearbyClinics);
      }
    );

  }

  async ngOnInit() {
    this.range = this.selectedOptionsService.getRange();
    await this.locationAvailabilityService.getClinics();
    this.locationsLoaded = true;
  }

  sortByClosest(list){
    list.sort((loc1, loc2) => {
      return loc1.distanceAway - loc2.distanceAway;
    })
  }

}
