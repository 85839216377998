import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DisplayLocationDetailsService } from './services/display-location-details.service';

@Injectable({
  providedIn: 'root'
})
export class MobileControlDialogService {
  dialogOpen = false;
  dialogOpenChange$ : Subject<boolean> = new Subject<boolean>();
  constructor(
    private displayLocationDetailsService : DisplayLocationDetailsService
  ) { }

  closeDialog(){
    this.displayLocationDetailsService.hideDetails();
  }
}
