import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationDataService {
  url = "/api/ohio-locations";

  constructor(private http : HttpClient) { }

  getLocations(){
    return this.http.get(this.url).toPromise();
  }
}
