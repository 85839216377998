import { Component, OnInit } from '@angular/core';
import { Location } from '../models/location.model';
import { DisplayLocationDetailsService } from '../services/display-location-details.service';

@Component({
  selector: 'app-street-container',
  templateUrl: './street-container.component.html',
  styleUrls: ['./street-container.component.css']
})
export class StreetContainerComponent implements OnInit {
  panorama: google.maps.StreetViewPanorama
  currentLocation : Location

  constructor(
    private displayLocationDetailsService : DisplayLocationDetailsService
    ) { 
      displayLocationDetailsService.getLocationDataChanged().subscribe(
        async location =>{
          console.log("adjusting location on street container")
          this.currentLocation = await location;
          this.panorama.setPosition(new google.maps.LatLng(this.currentLocation.cityCoords.latitude,this.currentLocation.cityCoords.longitude))
        }
      );
    }

  ngOnInit(): void {
    this.initialize();
  }

  async initialize(){
    
    this.currentLocation = await this.displayLocationDetailsService.locationData;
    this.panorama = new google.maps.StreetViewPanorama(
      document.getElementById('street-view-container') as HTMLElement,
      {
        position: { lat: this.currentLocation.cityCoords.latitude, lng: this.currentLocation.cityCoords.longitude },
        pov: { heading: 165, pitch: 0 },
        zoom: 1,
      }
    );
    console.log(this.currentLocation);
  }
}
