import { Component } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon'
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClientModule} from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'vaccine';
  providerIds = [40,47,70,19,41];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) {

    //register svg icons for each provider
    this.providerIds.forEach(id=>{
      this.matIconRegistry.addSvgIcon(
        `svg${id}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/providers/${id}.svg`)
      );
    });
    //register placeholder svg as svg1
    this.matIconRegistry.addSvgIcon(
      'svg1',this.domSanitizer.bypassSecurityTrustResourceUrl('/providers/placeholder.svg')
    );
  }
}
