import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { SearchingLocationService } from './searching-location.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UpdateLocationService {
  currentMarkerCoords = {
    latitude: 39.983334,
    longitude: -82.983330
  }
  geoLocationError$ : Subject<string>
  currentMarkerChanged$ : Subject<{}>
  pageLoadedChanged$ : Subject<{}>
  moveMarkerChanged$ : Subject<{}>
  geoDataChanged$ : Subject<{}>
  geoData;
  geoDataRequestUrl = "/api/geocoding";

  constructor(
    private searchingLocationService:SearchingLocationService,
    private http:HttpClient
  ) { 
    this.geoLocationError$ = new Subject();
    this.currentMarkerChanged$ = new Subject();
    this.moveMarkerChanged$ = new Subject();
    this.pageLoadedChanged$ = new Subject();
    this.geoDataChanged$ = new Subject();
    this.searchingLocationService.getFoundLocationSubject().subscribe(
      foundLocation =>{
        console.log("update location service")
        const coords = {
          latitude: foundLocation["geometry"]["location"]["lat"],
          longitude: foundLocation["geometry"]["location"]["lng"]
        }
        console.log(coords);
        this.setCurrentMarkerCoords(coords);
        this.moveMarkerChanged$.next(coords);
      }
    );
  }

  getMoveMarkerSubject(){
    return this.moveMarkerChanged$;
  }

  getCurrentMarkerObservable(){
    return this.currentMarkerChanged$;
  }

  getPageLoadedSubject(){
    return this.pageLoadedChanged$;
  }
  getCurrentMarkerCoords(){
    return this.currentMarkerCoords;
  }

  getGeoDataSubject(){
    return this.geoDataChanged$;
  }

  getGeoLocationErrorSubject(){
    return this.geoLocationError$;
  }

  async loadCurrentMarkerCoords(){
    let coords = {};
    navigator.geolocation.getCurrentPosition(
      position => {
        coords = position.coords;
        this.setCurrentMarkerCoords(coords);
        this.pageLoadedChanged$.next(true);
      },
      error => {
        console.log("no geolocation loaded")
        coords = {
          latitude: 41.505493,
          longitude: -81.681290
        },
        this.setCurrentMarkerCoords(coords);
        this.geoLocationError$.next("Enter a location to search for available appointments.")        
        this.pageLoadedChanged$.next(false);
      },
      {
        timeout: 10000
      }
    )
  }

  setCurrentMarkerCoords(argCoords){
    this.currentMarkerCoords = argCoords;
    console.log(this.currentMarkerCoords)
    this.currentMarkerChanged$.next(this.currentMarkerCoords);
    this.findGeoData(argCoords);
  }

  async findGeoData(argCoords){
    console.log("find geodata")
    const lat = argCoords["latitude"];
    const lng = argCoords["longitude"];
    const response = await this.http.get(this.geoDataRequestUrl+`?lat=${lat}&lng=${lng}`).toPromise();
    this.geoData = response;
    this.geoDataChanged$.next(this.geoData);
  }
}
