import { Component, OnInit } from '@angular/core';
import {MatIconModule} from'@angular/material/icon';
import {MatDividerModule} from'@angular/material/divider';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})
export class PageFooterComponent implements OnInit {
  pfizerUrl = "https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/Pfizer-BioNTech.html"
  modernaUrl = "https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/Moderna.html"
  jjUrl = "https://www.cdc.gov/coronavirus/2019-ncov/vaccines/different-vaccines/janssen.html"
  getShotUrl = "https://gettheshot.coronavirus.ohio.gov/"
  cdcUrl = "https://www.cdc.gov/vaccines/covid-19/index.html"
  vaccinespotterUrl = "https://www.vaccinespotter.org/"

  constructor() { }

  ngOnInit() {
  }

}
