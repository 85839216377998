import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchingLocationService {
  isSearching: boolean;
  isSearchingSubject : Subject<boolean> = new Subject<boolean>();
  foundLocationSubject : Subject<{}> = new Subject<{}>();
  url = "/api/getplaces";
  constructor(
    private http:HttpClient
  ) { }

  getIsSearchingSubject(){
    return this.isSearchingSubject;
  }

  setToSearching(){
    this.isSearching=true;
    this.isSearchingSubject.next(this.isSearching);
  }

  setToNotSearching(){
    this.isSearching=false;
    this.isSearchingSubject.next(this.isSearching);
  }

  async searchForLocation(locationQuery){
    const response = await this.http.get(this.url+`?query=${locationQuery}`).toPromise();
    const foundLocation = response["candidates"][0];
    this.foundLocationSubject.next(foundLocation);
    return foundLocation;
  }

  getFoundLocationSubject(){
    return this.foundLocationSubject;
  }
}
