import { Component, OnInit, Input } from '@angular/core';
import {MatIconModule} from '@angular/material/icon'



@Component({
  selector: 'app-city-icon',
  templateUrl: './city-icon.component.html',
  styleUrls: ['./city-icon.component.css']
})
export class CityIconComponent implements OnInit {
  @Input() providerId;
  

  constructor() {
      
   }

  ngOnInit() {
    
  }

}
