import { Component, OnInit } from '@angular/core';
import { MatSnackBarModule, MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private snackBar : MatSnackBar) { 
    
  }

  ngOnInit() {
    this.snackBar.open("Search for places with COVID-19 vaccine appointments. Still in early development.","Close");
  }

}
