export class Location {
    id: number;
    url: String;
    city: String;
    name: String;
    state: String;
    address: String;
    distanceAway: number;
    cityCoords: {
        latitude: number,
        longitude: number
    };
    providerId: number;
    vaccineType: String;

    constructor(){

    }
}
