import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule} from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CityIconComponent } from './city-icon/city-icon.component';
import { CityCardComponent } from './city-card/city-card.component';
import { CityListComponent } from './city-list/city-list.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { InfoModalOverlayComponent } from './info-modal-overlay/info-modal-overlay.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import {GoogleMapsModule} from '@angular/google-maps';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocationDetailsComponent } from './location-details/location-details.component';
import { StreetContainerComponent } from './street-container/street-container.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import { SettingsListComponent } from './settings-list/settings-list.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ClinicListComponent } from './clinic-list/clinic-list.component';

@NgModule({
  declarations: [
    AppComponent,
    CityIconComponent,
    CityCardComponent,
    CityListComponent,
    GoogleMapComponent,
    InfoModalOverlayComponent,
    PageFooterComponent,
    HomeComponent,
    LocationDetailsComponent,
    StreetContainerComponent,
    SettingsListComponent,
    ClinicListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    MatCardModule,
    ScrollingModule,
    MatFormFieldModule,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatExpansionModule,
    MatSidenavModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDividerModule,
    MatSnackBarModule
  ],
  exports: [
    GoogleMapComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
