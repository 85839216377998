import { Component, OnInit, Input } from '@angular/core';
import getDistance from 'geolib/es/getDistance';
import {convertDistance} from 'geolib';
import {MatCardModule} from '@angular/material/card';
import { Subject } from 'rxjs';
import {MatIconModule} from '@angular/material/icon'
import { DisplayLocationDetailsService } from '../services/display-location-details.service';

@Component({
  selector: 'app-city-card',
  templateUrl: './city-card.component.html',
  styleUrls: ['./city-card.component.css']
})
export class CityCardComponent implements OnInit {
  @Input() location;
  @Input() cityIsSelected : boolean;
  isActive : boolean;
  citySelectedChanged$ : Subject<{}>
  placeholderImageId = 1;

  constructor(
    private displayLocationDetailsService:DisplayLocationDetailsService

  ) { 
    
  }

  ngOnInit() {
  }

  cityToggle(id : number){
    // reset the active state on any existing tab
    if(this.displayLocationDetailsService.activeTab){
      this.displayLocationDetailsService.activeTab.isActive = false;
    }

    // identify clicked tab as being active
    this.displayLocationDetailsService.activeTab = this;

    // hide if showing & the same location
    if(this.displayLocationDetailsService.showingDetails && id == this.displayLocationDetailsService.locationData.id){
      this.isActive = false;
      this.citySelected(false)
    }
    // show if hiding
    else{
      this.isActive = true;
      this.citySelected(true);
    }
  }

  citySelected(bool){
    if(bool){
      this.displayLocationDetailsService.showDetails(this.location);
    }
    else{this.displayLocationDetailsService.hideDetails();
    }
  }

  cityDeselected(){
    this.displayLocationDetailsService.hideDetails();
  }

}
